#root.lims {
    .button.is-primary:not(.is-outlined) {
        background-color: $lims-bgdark !important;

        &:hover {
            background-color: $lims-bgdarker !important;
        }
    }

    .button.is-primary.is-outlined {
        border-color: $lims-bgdark !important;
        color: $lims-bgdark !important;

        &:hover {
            background-color: $lims-bgdarker !important;
            color: tint($eln-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $lims-bgdarker !important;
            color: tint($eln-bgdark, 90%) !important;
        }
    }

    .button.is-link:not(.is-outlined) {
        // background-color: tint($lims-bgdark, 80%) !important;
        background-color: #e9ecef !important;

        &:hover {
        // background-color: tint($lims-bgdark, 70%) !important;
        background-color: #084c68;
        color: #fff;
        }
    }

    .button.is-outlined.is-info {
        border-color: $lims-bgdark !important;
        color: $lims-bgdark !important;

        &:hover {
            background-color: $lims-bgdark !important;
            color: tint($lims-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $lims-bgdark !important;
            color: tint($lims-bgdark, 90%) !important;
        }
    }
}

#root.lims-eln {
    .button.is-primary:not(.is-outlined) {
        background-color: $lims-eln-bgdark !important;

        &:hover {
            background-color: $lims-eln-bgdarker !important;
        }
    }

    .button.is-primary.is-outlined {
        border-color: $lims-eln-bgdark !important;
        color: $lims-eln-bgdark !important;

        &:hover {
            background-color: $lims-eln-bgdarker !important;
            color: tint($lims-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $lims-eln-bgdarker !important;
            color: tint($lims-bgdark, 90%) !important;
        }
    }

    .button.is-link:not(.is-outlined) {
        background-color: tint($lims-eln-bgdark, 80%) !important;

        &:hover {
            background-color: tint($lims-eln-bgdark, 70%) !important;
        }
    }

    .button.is-outlined.is-info {
        border-color: $lims-eln-bgdark !important;
        color: $lims-eln-bgdark !important;

        &:hover {
            background-color: $lims-eln-bgdark !important;
            color: tint($lims-eln-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $lims-eln-bgdark !important;
            color: tint($lims-eln-bgdark, 90%) !important;
        }
    }

}

#root.eln {
    .button.is-primary:not(.is-outlined) {
        background-color: $eln-bgdark !important;

        &:hover {
            background-color: $eln-bgdarker !important;
        }
    }

    .button.is-primary.is-outlined {
        border-color: $eln-bgdark !important;
        color: $eln-bgdark !important;

        &:hover {
            background-color: $eln-bgdarker !important;
            color: tint($eln-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $eln-bgdarker !important;
            color: tint($eln-bgdark, 90%) !important;
        }
    }

    .button.is-link:not(.is-outlined) {
        background-color: tint($eln-bgdark, 80%) !important;

        &:hover {
            background-color: tint($eln-bgdark, 70%) !important;
        }
    }

    .button.is-outlined.is-info {
        border-color: $eln-bgdark !important;
        color: $eln-bgdark !important;

        &:hover {
            background-color: $eln-bgdark !important;
            color: tint($eln-bgdark, 90%) !important;
        }
        &:focus {
            background-color: $eln-bgdark !important;
            color: tint($eln-bgdark, 90%) !important;
        }
    }
}