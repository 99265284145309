.login {
  display: flex;
  height: 100vh;
  background-position-y: -40px;
  margin-top: -4rem;

  &.lab-login {
    background: $lims-bgdarker;
    background: $lims-gradient;
    background-image: $lab-bg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
  }

  &.lims-login {
    background: $lims-bgdarker;
    background: $lims-gradient;
    background-image: $lims-bg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
  }

  &.eln-login {
    background: $lims-bgdarker;
    background: $lims-gradient;
    background-image: $eln-bg;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.lims-eln-login {
    background: $lims-bgdarker;
    background: $lims-gradient;
    background-image: $lims-eln-bg;
    background-repeat: no-repeat;
    background-size: cover;

    .form-signin {
      background-color: #2b4586b6;
    }
  }

  .help.is-danger {
    color: hsl(348, 100%, 86.3%) !important;
  }
  
  .button.has-text-grey-light {
    color: hsl(0, 0%, 85.1%) !important;
  }

  .lm-login-inner {
    min-height: 100vh;
    width: 100%;
    background: transparent;
  }

  .form-signin {
    flex-grow: 1;
    padding: 24px 40px 36px;
  }

  .form-signin .logos {
    display: block;
    min-width: 200px;
    max-width: 355px;
    width: 100%;
    min-height: 37px;
    background-position: center;
    border: none;
    margin: 0 auto 3rem auto;
  }

  .form-signin .lab-logo {
    background: $lab-logo;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 99px;

    @media all and (min-width: 769px) {
      height: 100%;
      position: absolute;
      right: 46%;
      max-width: 24%;
    }
  }

  .form-signin .lims-logo {
    background: $lims-logo;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-signin .eln-logo {
    background: $eln-logo;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-signin .lims-eln-logo {
    background: $lims-eln-logo;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .form-signin .checkbox {
    font-weight: 400;
  }

  .form-signin .label {
    color: #ffffff !important;
    font-weight: normal !important;
  }

  .form-signin .input {
    background-color: #ffffff47 !important;
    border-color: #dbdbdb61 !important;
    color: #ffffff !important;
  }

  .form-signin .control.has-icons-left .icon,
  .form-signin .control.has-icons-right .icon {
    color: #dbdbdb !important;
  }

  .form-signin .input::placeholder,
  .form-signin .select select::placeholder,
  .form-signin .textarea::placeholder {
    color: #dbdbdb !important;
  }

  .form-signin .css-yk16xz-control,
  .form-signin .css-1pahdxg-control {
    background-color: #ffffff47 !important;
    border-color: #dbdbdb61 !important;
    color: #ffffff !important;
  }

  .form-signin .css-1uccc91-singleValue {
    color: #ffffff !important;
  }

  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    border: 1px solid #ced4da !important;
  }

  .validation-error {
    font-size: 10px;
    padding: 2px 0 0 0;
  }

  .validation-error::before {
    content: "ERROR - ";
    color: red;
  }

  @media all and (min-width: 769px) {
    .lm-login {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      position: relative;
      justify-content: center;
    }

    .lm-login:before,
    .lm-login:after {
      content: "";
      display: none;
      box-flex: 1;
      flex-grow: 1;
      height: 24px;
    }

    .lm-login-inner {
      width: 450px;
      min-height: 0;
      border-radius: 0;
      border: 1px solid transparent;
      display: block;
      flex-shrink: 0;
      margin-left: auto;
      transition: 0.2s;
      display: flex;
      align-items: center;
    }

    .form-signin {
      padding: 48px 40px 36px !important;
    }
  }

  .button.is-info {
    background-color: $login-button !important;

    &:hover {
      background-color: shade($login-button, 7%) !important;
    }
  }
}
