/* Global CSS */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v34-latin-300.eot');
  src: local(''), url('../fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v34-latin-300.woff2') format('woff2'), url('../fonts/open-sans-v34-latin-300.woff') format('woff'), url('../fonts/open-sans-v34-latin-300.ttf') format('truetype'), url('../fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v34-latin-regular.eot');
  src: local(''), url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'), url('../fonts/open-sans-v34-latin-regular.woff') format('woff'), url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'), url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v34-latin-600.eot');
  src: local(''), url('../fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v34-latin-600.woff2') format('woff2'), url('../fonts/open-sans-v34-latin-600.woff') format('woff'), url('../fonts/open-sans-v34-latin-600.ttf') format('truetype'), url('../fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v34-latin-700.eot');
  src: local(''), url('../fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v34-latin-700.woff2') format('woff2'), url('../fonts/open-sans-v34-latin-700.woff') format('woff'), url('../fonts/open-sans-v34-latin-700.ttf') format('truetype'), url('../fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v34-latin-800.eot');
  src: local(''), url('../fonts/open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v34-latin-800.woff2') format('woff2'), url('../fonts/open-sans-v34-latin-800.woff') format('woff'), url('../fonts/open-sans-v34-latin-800.ttf') format('truetype'), url('../fonts/open-sans-v34-latin-800.svg#OpenSans') format('svg');
}


html {
  margin: 0;
  height: 100%;
  overflow-y: auto !important;
  font-size: 14px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'OpenSans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  height: 100%;
  margin: 0 !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #333333 !important;
  padding-top: 0px;
  padding-bottom: 40px;
}

@media not print {
  body {
    font-family: 'Open Sans', sans-serif !important;
  }
}

.is-family-default {
  font-family: 'Open Sans', sans-serif !important;
  font-size: .95rem !important;
}

.c-json-view,
.c-json-content .c-json-key {
  font-size: 0.98rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  font-family: 'Open Sans', sans-serif !important;
}

#root {
  height: 100%;
  padding-top: 4rem;
}

.is-flex-basis-0 {
  flex-basis: 0 !important;
}

.css-1p3m7a8-multiValue {
  max-width: 300px;
}

/* Bulma Overwrite */
.file-cta,
.file-name,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select select,
.textarea {
  font-family: inherit;
  font-size: 1rem;
}

.input[disabled],
.select fieldset[disabled] select,
.select select[disabled],
.textarea[disabled],
fieldset[disabled] .input,
fieldset[disabled] .select select,
fieldset[disabled] .textarea {
  border-color: #eeeded !important;
}

.input:not(:hover),
.textarea:not(:hover) {
  box-shadow: none !important;
}

button,
.button input,
optgroup,
select,
textarea {
  font-family: inherit !important;
  font-size: 0.9rem;
}

.input::placeholder,
.select select::placeholder,
.textarea::placeholder,
.css-1jqq78o-placeholder,
.css-1pgjs0x-placeholder,
.css-1dimb5e-singleValue {
  font-size: 0.9rem !important;
  font-family: inherit;
}

/* Preloader CSS */
#loader {
  background: rgb(0, 0, .8) !important;
}

.lm-preload {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.lm-item {
  width: 40px;
  height: 40px;
  position: absolute;
  border: 2px solid #ffffff;
}

.lm-item-1 {
  background-color: #FCAD65;
  top: 0;
  left: 0;
  z-index: 1;
  animation: lm-item-1_move 2.8s cubic-bezier(.6, .01, .4, 1) infinite;
}

.lm-item-2 {
  background-color: #B4CC77;
  top: 0;
  right: 0;
  animation: lm-item-2_move 2.8s cubic-bezier(.6, .01, .4, 1) infinite;
}

.lm-item-3 {
  background-color: #0BA3CB;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: lm-item-3_move 2.8s cubic-bezier(.6, .01, .4, 1) infinite;
}

.lm-item-4 {
  background-color: #8CB6C0;
  bottom: 0;
  left: 0;
  animation: lm-item-4_move 2.8s cubic-bezier(.6, .01, .4, 1) infinite;
}

.lm-item-5 {
  background-color: #ffffff;
  bottom: 0;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 2;
}

.lm-item-5::before {
  content: "L";
  color: #4499b0;
  font-size: 2em;
  font-weight: bold;
  left: calc(50% - 8px);
  top: calc(50% - 21px);
  z-index: 3;
  position: absolute;
}

@keyframes lm-item-1_move {

  0%,
  100% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(0, 40px)
  }

  50% {
    transform: translate(40px, 40px)
  }

  75% {
    transform: translate(40px, 0)
  }
}

@keyframes lm-item-2_move {

  0%,
  100% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(-40px, 0)
  }

  50% {
    transform: translate(-40px, 40px)
  }

  75% {
    transform: translate(0, 40px)
  }
}

@keyframes lm-item-3_move {

  0%,
  100% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(0, -40px)
  }

  50% {
    transform: translate(-40px, -40px)
  }

  75% {
    transform: translate(-40px, 0)
  }
}

@keyframes lm-item-4_move {

  0%,
  100% {
    transform: translate(0, 0)
  }

  25% {
    transform: translate(40px, 0)
  }

  50% {
    transform: translate(40px, -40px)
  }

  75% {
    transform: translate(0, -40px)
  }
}

.pageOverlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: block;
  text-align: center;
}

@media (max-width: 991px) {
  .loader {
    margin-top: -25px;
    margin-left: -25px;
  }
}


/* Side Navigation */

.sidebar-menu-item:hover .sidebar-label,
#sidebar-menu .sidebar-menu-item:hover .icon .mdi {
  color: rgba(0, 0, 0, .9) !important;
}


#sidebar-menu .sidebar-menu-item .icon .mdi,
.sidebar-menu-item .sidebar-label {
  -webkit-transition: color 0.1s ease-out;
  -moz-transition: color 0.1s ease-out;
  -o-transition: color 0.1s ease-out;
  transition: color 0.1s ease-out;
}

.c-icon {
  position: relative;
}

.c-icon::after {
  position: relative;
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  text-rendering: auto;
  position: absolute;
  left: 10px;
  bottom: 4px;
  border-radius: 50%;
  font-size: 0.9rem;
}

.s-setting,
.s-cross,
.admin-s {
  position: relative;
}

.admin-s::after {
  content: "\F0705";
  color: #666666 !important;
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  text-rendering: auto;
  position: absolute;
  left: 10px;
  bottom: 4px;
  background: #fff;
  border-radius: 50%;
  font-size: 0.85rem;
}

.s-setting::after {
  content: "\F08BB";
  color: #666666 !important;
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  line-height: 1;
  text-rendering: auto;
  position: absolute;
  left: 10px;
  bottom: 4px;
  background: #fff;
  border-radius: 50%;
  font-size: 0.85rem;
}

.s-cross::after {
  content: "\F0156";
  color: #fff !important;
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 24px;
  line-height: 1;
  line-height: 1;
  text-rendering: auto;
  position: absolute;
  left: 2px;
  bottom: 4px;
  background: red;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
}

.nav-link-expand .sub-menu {
  display: block !important;
}

@media screen and (min-width: 1280px) {
  .sidebar {
    width: 24rem;
  }

  main {
    margin-left: 24rem;
    transition: margin-left 0.5s ease;
  }
}

@media screen and (max-width: 1280px) {
  .sidebar {
    width: 24rem;
  }
}

@media screen and (max-width: 1023px) {
  /* .sidebar {
    width: 0px;
  } */

  .sidebar-active {
    width: 24rem !important;
    box-shadow: 3px 0px 12px -6px #a8a8a8;
  }

  .menu-icon {
    z-index: 999999;
    margin-top: -1rem !important;
    color: #fff !important;
  }

  .nav-link {
    white-space: nowrap;
    transition: padding 0.3s;
    padding: 12px 16px;
  }

  /* main {
    margin-left: 0 !important;
  } */

  .navbar-end {
    justify-content: flex-end !important;
    margin-left: auto !important;
  }

  .navbar-menu {
    background: transparent !important;
  }

  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: stretch;
    display: flex;
  }
}

.navbar-brand {
  width: 27rem !important;
}

.navbar-brand .navbar-item {
  width: 100% !important;
}

.navbar-brand .navbar-item:hover {
  background: transparent !important;
}

.navbar-brand .navbar-item img {
  margin: 0 !important;
  max-height: 2.11rem !important;
}

.navbar-menu {
  box-shadow: none !important;
  display: flex !important;
  flex-grow: 1;
  flex-shrink: 0;
}

.navbar-menu .icon i {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: color 0.1s ease-out;
  -moz-transition: color 0.1s ease-out;
  -o-transition: color 0.1s ease-out;
  transition: color 0.1s ease-out;
}

.navbar-menu .icon i::before {
  font-size: 22px !important;
}

.navbar-menu .navbar-dropdown .has-icons-left .icon i {
  color: rgba(0, 0, 0, 0.4) !important;
}

.navbar-menu .navbar-item:hover .icon i {
  color: rgba(255, 255, 255, 1);
}

main {
  position: relative;
  background: #ffffff;
  padding: 1rem;
  transition: margin-left 0.3s;
  min-height: calc(100vh - 4rem);
}


.form-comp-label {
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  border: 0;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: .32px;
  display: inline-block;
  margin: 0 0 .5rem;
  color: #525252;
  line-height: 1rem;
  vertical-align: baseline;
}

.filter-comp-label {
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  border: 0;
  line-height: 1.34;
  letter-spacing: .32px;
  display: inline-block;
  color: #525252;
  vertical-align: baseline;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  z-index: 0 !important;
}

.css-15lsz6c-indicatorContainer,
.css-1xc3v61-indicatorContainer {
  color: #8d8d8d !important;
  padding: 7px 8px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ani-slide-down {
  transition: all 0.3s ease-out;
  height: auto;
}

.required {
  color: red;
}

.button.is-link {
  border: none;
  /* color: #333333 !important; */
  color: #fff;
}

.button.is-link:hover {
  border: none;
}

.css-t3ipsp-control:hover {
  border-color: #ced4da !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  height: 2.5em !important;
  min-height: 35px !important;
}

.sleekinput .react-datepicker-wrapper input {
  font-size: 0.75rem !important;
}

.sleekinput .react-datepicker-wrapper input,
.sleekinput .css-1fhf3k1-control,
.sleekinput .css-13cymwt-control,
.sleekinput .css-t3ipsp-control,
.sleekinput__control {
  height: 1.5em !important;
  min-height: 26.5px !important;
  border-radius: 2px !important;
  min-width: 55px;
  flex-wrap: nowrap;
}

.sleekinput .css-1fhf3k1-control div,
.sleekinput .css-13cymwt-control div,
.sleekinput .css-t3ipsp-control div,
.sleekinput__control div {
  padding: 0 !important;
  line-height: 1 !important;
}

.sleekinput .css-1fhf3k1-control>div,
.sleekinput .css-13cymwt-control>div,
.sleekinput .css-t3ipsp-control>div,
.sleekinput__control>div {
  padding: 0 3px !important;
}

.sleekinput .css-1fhf3k1-control svg,
.sleekinput .css-13cymwt-control svg,
.sleekinput .css-t3ipsp-control svg,
.sleekinput__control svg {
  height: 15px !important;
}

.multiselectInput .css-13cymwt-control,
.multiselectInput .css-t3ipsp-control,
.react-select-tags .css-13cymwt-control,
.react-select-tags .css-t3ipsp-control {
  height: auto !important;
  min-height: 35px !important;
}

.react-datepicker-popper {
  z-index: 999;
}

.is-narrow input {
  width: 100% !important;
}

.css-t3ipsp-control {
  border-color: #3273dc !important;
  box-shadow: 0 0 0 .125em rgba(50, 115, 220, .25) !important;
}

.sorting .css-13cymwt-control,
.sorting .css-t3ipsp-control,
.add-user .css-13cymwt-control,
.add-user .css-t3ipsp-control,
.act-filter .css-13cymwt-control,
.act-filter .css-t3ipsp-control {
  padding-left: 25px !important;
}

.add-user .mdi-account {
  padding-top: 5px !important;
}

.filter {
  max-width: 267px;
}

.filter button {
  width: 89px;
}

@media (max-width: 1023px) {
  .filter {
    width: 254px;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
  }

  .navbar.is-fixed-top .navbar-menu {
    overflow: visible !important;
  }

  .navbar-item:hover .navbar-dropdown {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(10, 10, 10, .1);
    display: block;
    position: absolute;
    min-width: 100%;
    position: absolute;
    top: calc(100% + .5rem);
    z-index: 20;
    width: 265px;
    /* height: 500px; */
    right: -10%;
  }

  /* .navbar-dropdown {
    display: none;
  } */
}

.sorting .has-icons-left .input:focus~.icon,
.dt-filter .input:focus-within .material-icons {
  color: #4a4a4a !important;
}

.dt-filter .react-datepicker-wrapper .react-datepicker__input-container>input {
  padding-left: 40px;
}

.react-tabs__tab {
  border: none !important;
  color: rgba(0, 0, 0, 0.55);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-width: 65px;
  text-align: center;
  margin-right: 3px;
  border-bottom: none !important;
}

.react-tabs__tab--selected {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-color: transparent !important;
  color: rgba(255, 255, 255, 0.8) !important;
  z-index: 1;
  font-weight: 500 !important;
}

.react-tabs__tab--selected::after {
  display: none !important;
}

.react-tabs__tab-list {
  background: #fff;
  z-index: 9;
  margin: 0 0 1px !important;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
}

.txt-primary {
  font-weight: 600;
  color: #131313;
}

.txt-primary:hover {
  cursor: pointer;
  text-decoration: underline;
}

.card-body {
  padding: 1rem;
}

.detail-value {
  color: #000;
  margin-left: 20px;
}

.icon-vert {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.pull-right {
  float: right;
}

.blank-div {
  text-align: center;
  padding: 2rem;
  font-size: 2em;
  font-weight: 300;
  color: #4b7a846b;
  opacity: 0.6;
}

.breadcrumb {
  margin-left: 0;
  margin-top: -1rem;
  position: relative;
  /* background: rgba(224, 237, 239, 0.52); */
}

.breadcrumb::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 41px;
  z-index: 1;
  width: calc(100% + 15px);
}

.breadcrumb::after {
  content: '';
  position: absolute;
  top: 0;
  right: -17px;
  width: 17px;
  height: 41px;
  z-index: 1;
}

.breadcrumb ul {
  margin-left: -15px;
  z-index: 1;
  position: relative;
}

.breadcrumb li {
  font-size: 12px !important;
  line-height: 40px !important;
  color: rgba(0, 0, 0, .5) !important;
  padding: 0 10px 0 30px !important;
  cursor: pointer;
  position: relative;
  transition: all 0.5s;
}

.breadcrumb ul li:first-child {
  padding-left: 30px !important;
}

@media screen and (min-width: 1600px) {
  .breadcrumb ul li:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 40px;
    height: 40px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    border-radius: 0 5px 0 50px;
    transition: all 0.5s;
  }
}

@media (min-width: 769px) {
  .is-narrow .css-b62m3t-container {
    min-width: 195px !important;
  }

  .w-half {
    max-width: 50%;
  }

  .min-half {
    min-width: 50%;
  }

  .is-pulled-left-tablet {
    float: left !important;
  }

  .data-label {
    align-items: flex-start !important;
  }

  .data-label .label {
    padding-top: .50rem !important;
  }

  .l-height {
    line-height: 1.2rem !important;
  }
}

.breadcrumb li+li::before {
  display: none;
}

.breadcrumb ul li:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
}

.breadcrumb ul li.is-active,
.breadcrumb ul li:hover {
  color: rgba(0, 0, 0, .9) !important;
}

.breadcrumb ul:hover .breadcrumb::before {
  background-color: rgb(191, 218, 223) !important;
}

.breadcrumb ul li:after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  width: 40px;
  height: 40px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  border-radius: 0 5px 0 50px;
  transition: all 0.5s;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -2px !important;
}

.subtitle {
  color: rgba(0, 0, 0, 0.9) !important;
}

.subtitle sub {
  color: #666666 !important;
  font-size: .60em !important;
}

.field-label .label {
  width: 100%;
}

.second-label-field {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1.5rem;
  text-align: right;
}

.security-forms .content p {
  color: rgba(0, 0, 0, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  letter-spacing: -0.03em;
}

.title {
  font-size: 1.9rem !important;
}

hr {
  margin: .5rem 0 1rem 0 !important;
}

.tableScrollBox {
  position: relative;
  overflow-x: auto;
}

.tableScrollBox table {
  min-width: 750px !important;
}

table thead th {
  border-width: 0 0 0 !important;
  font-size: 0.957rem !important;
}

.table td,
.table th {
  padding: .7em .75em !important;
  font-size: 0.97rem;

}

.table:not(.is-bordered) td,
.table:not(.is-bordered) th {
  border: none !important;

}

.table thead td,
.table thead th {
  color: rgba(255, 255, 255, 0.9) !important;
}

.is-flex-basis-50 {
  flex-basis: 50%;
}

.is-no-wrap {
  white-space: nowrap;
  margin-right: .75rem !important;
}

.is-no-wrap .is-small {
  font-size: 0.75rem !important;
  min-width: 62px;
}

.bd-copy {
  opacity: 0;
  margin-left: 10px;
}

.pass-sec:hover .bd-copy {
  opacity: 1;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999;
  display: flex;
}

.popup_content {
  position: absolute;
  background: white;
  left: 30%;
  top: 25%;
  padding: 15px;
  width: 600px;
}

.popup-title {
  font-size: 20px;
}

.popup-btn-panel {
  padding-top: 20px;
  float: right;
}

.popup-btn-panel>.btn {
  padding: 5px 10px !important;
}

.accordion {
  color: #444;
  cursor: pointer;
  padding: 6px 0px 7px 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: all 0.4s;
  margin: 10px 0;
}

.accordian-head {
  cursor: pointer;
  padding: 5px 0px 5px 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.7);
}

.accordion-incomplete {
  background-color: #FBEADF;
  border: 1px solid #F9DCCA;
}

.accordion-incomplete>span,
.accordion-complete>span {
  font-size: .87rem;
  vertical-align: middle;
}

.accordion-complete {
  background-color: #cbe0e5;
  border: 1px solid #cbe0e5;
}

.active {
  background-color: #d2e2e6;
}

.accordion-complete .move-box {
  box-shadow: inset 0 0 1px #b0d6df;
  padding: 2px;
  background: #b9d8df;
}

.accordion-incomplete .move-box {
  box-shadow: inset 0 0 1px #E38D79;
  padding: 2px;
  background: #d5675b4a;
}

.accordion-panel {
  background-color: white;
  margin-top: -10px;
  margin-bottom: 10px;
}

.expando-toggle-icon {
  padding-left: 7px;
}

.moveUp,
.moveUp::before,
.moveDown,
.moveDown::before {
  line-height: 13px;
  font-size: 20px;
}

.editMove .moveUp,
.editMove .moveUp::before,
.editMove .moveDown,
.editMove .moveDown::before {
  line-height: 17px;
  font-size: 20px;
  height: auto;
  color: #a6a5a5;
}

.drawer {
  position: absolute;
  top: 70px;
  right: 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15);
  height: 100%;
  overflow-x: hidden;
  padding-top: 0;
  max-width: 300px;
  max-height: 300px;
  min-height: 130px;
}

.user-auths {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 3px;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0;
  max-height: 300px;
  min-height: 130px;
  overflow-y: auto;
}

.user-auths ul,
.drawer ul {
  box-sizing: content-box;
  margin: 0;
  padding: 14px 0 0 14px;
  width: 300px;
}

.user-auths ul li,
.drawer ul li {
  display: inline-block;
  height: 50px;
  list-style-type: none;
  padding: 6px;
  position: relative;
  transition: transform .2s cubic-bezier(.333, 0, 0, 1);
  vertical-align: top;
  width: 84px;
  text-align: center;
  cursor: pointer;
}

.menu-icon:hover {
  background-color: #f1f5f7;
}

.h-user-type {
  color: #00687E !important;
  font-weight: bold;
}

.user-auths {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 3px;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0;
  max-height: 300px;
  min-height: 130px;
}

.message.is-light .message-header {
  color: rgba(0, 0, 0, 0.77) !important;
  text-transform: uppercase;
  font-size: 0.92em;
}

.itab {
  padding: 0 15px !important;
  color: rgba(0, 0, 0, .5) !important;
  position: relative;
}

.header-active {
  color: rgba(0, 0, 0, 1) !important;
}

.header-active::after {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 2px;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, .5);

}

.message.is-light .message-header i:before {
  line-height: 10px;
  font-size: 17px;
}

.message.is-light .message-header>span {
  font-size: .9rem;
}

.sub-filter {
  margin: 0 0 .75rem 0 !important;
  padding: 0 !important;
  flex-wrap: wrap;
}

.sub-filter input,
.sub-filter .input {
  min-width: 144px !important;
}

.site-type {
  text-align: right;
}

.site-type div:nth-child(1) {
  font-size: 0.835rem;
  color: rgba(255, 255, 255, 0.9);
}

.site-type div:nth-child(2) {
  font-size: 0.83rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 0.8rem;
}

.table td.status-active {
  color: green !important;
  font-weight: bold;
}

.table td.status-inactive {
  color: #d51515 !important;
  font-weight: bold;
}

.table td.status-awaiting {
  color: #f0960a !important;
  font-weight: bold;
}

.table td.status-disabled {
  color: #d51515 !important;
  font-weight: bold;
}

.tag-sm {
  border-radius: 7px !important;
  height: 1.5em !important;
  padding-left: .45em !important;
  padding-right: .45em !important;
}

.tag-sm i.mdi::before {
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.child-table thead tr:hover,
.child-table tbody tr:hover,
.child-table tbody td:hover {
  background-color: transparent !important;
}

.child-table tbody td {
  padding: 0 10px !important;
}

.child-table .mdi::before {
  line-height: 0.5rem !important;
}

.equal-col td {
  width: 20%;
  color: #919191 !important;
}

.vertical-timeline-element-content div {
  word-break: break-all;
}

@media screen and (min-width:1024px) {
  /* .navbar-dropdown {
    right: 0 !important;
  } */
}

.navbar-dropdown {
  border-top: 2px solid #ed7d31 !important;
}

.txt-secondry,
.txt-primary {
  word-break: break-all;
}

.tab-count {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: .75rem;
  line-height: 1.75;
  border-radius: 4px;
  height: 1.5em;
  padding: 4px 5px;
  color: #fafafa;
  text-align: center;
  margin-left: 5px;
}

.react-tabs__tab--selected .tab-count {
  color: #232323;
  background: rgba(255, 255, 255, 0.8);
}

.head-bg {
  width: 100%;
  border-radius: 2px;
  color: #888888 !important;
  font-weight: 300;
  text-transform: uppercase;
  background: #e5e5e5 !important;
  /*background: -webkit-linear-gradient(to top, #00687E, #00829c) !important;
  background: -moz-linear-gradient(to top, #00687E, #00829c) !important;
  background: -o-linear-gradient(to top, #00687E, #00829c) !important;
  background: linear-gradient(to top, #00687E, #00829c) !important; */
}

.head-bg.p-1 {
  padding-top: 0.50rem !important;
  padding-bottom: 0.50rem !important;
}

.custom-file-upload {
  position: relative;
  font-family: arial;
  overflow: hidden;
  margin-bottom: 10px;
  width: auto;
  display: inline-block;
  padding: 10px;
}

.custom-file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
}

.custom-file-upload img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

ul.file-list {
  font-family: arial;
  list-style: none;
  padding: 0;
}

ul.file-list li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

.remove-list {
  cursor: pointer;
  margin-left: 10px;
}

.react-tags-wrapper {
  display: flex;
  height: auto;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  flex-wrap: wrap;
}

.ReactTags__tag {
  padding-right: 20px !important;
}

.has-biglabel .field-label {
  flex-grow: 2 !important;
}

/* Styles for the input */

.ReactTags__tagInput {
  width: 60px;
  border-radius: 2px;
  flex-grow: 1;
  flex-basis: 0;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 33px;
  margin: 0;
  padding-left: 5px;
  font-size: 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  color: #363636;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.ReactTags__tagInput input.ReactTags__tagInputField:focus .react-tags-wrapper {
  border-color: #3273dc !important;
  box-shadow: 0 0 0 .125em rgba(50, 115, 220, .25) !important;
}

/* Styles for selected tags */

.ReactTags__selected span.ReactTags__tag {
  border: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  display: inline-block;
  padding: 3px 5px;
  margin: 4px 2px;
  border-radius: 4px;
  position: relative;
}

.css-1rhbuit-multiValue .css-12jo7m5 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.ReactTags__selected {
  padding: 0 3px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 1;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: #ffffff75;
  color: #040404;
  position: absolute;
  border-radius: 7px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  padding: 0;
  top: 50%;
  margin-top: -6px;
  right: 5px;
  font-size: 13px;
}

.full-multiSelectCombo .is-offset-half {
  margin-left: auto !important;
  width: 100% !important;
}

.custom-check,
.custom-radio {
  display: block;
  position: absolute;
  padding-left: 0;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-check input,
.custom-radio input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
  height: 18px;
  width: 18px;
  left:0;
  right:0;
}

.custom-check input:checked:disabled~.checkmark,
.custom-radio input:checked:disabled~.radiomark,
.custom-check input:disabled~.checkmark,
.custom-radio input:disabled~.radiomark {
  background-color: #a4a1a1;
  border-color: #a4a1a1;
  cursor: not-allowed;
}

.checkmark,
.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
}

.custom-check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-radio .radiomark:after {
  top: 5px;
  left: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.custom-check:hover input~.checkmark,
.custom-radio:hover input~.radiomark {
  border-color: #b5b5b5;
}

.custom-check input:checked~.checkmark:after,
.custom-radio input:checked~.radiomark:after {
  display: block;
}

.radiomark {
  border-radius: 50%;
}

.checkmark:after,
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkblock1,
.checkblock2 {
  min-height: 35px;
  display: flex;
  align-items: center;
}

.field-height {
  min-height: 35px;
  align-items: center;
  display: flex;
}

.auth-check {
  height: 20px !important;
  width: 20px !important;
  min-width: 20px;
  padding: 0 !important;
}

.auth-check i::before {
  font-size: 15px !important;
}

.auth-check~span {
  line-height: 1.2rem !important;
  color: #4a4a4a;
  font-size: 0.97rem !important;
}

.caps-header {
  text-transform: uppercase;
  font-weight: 600;
  font-size: .92rem !important;
}

.checkblock1 {
  flex-grow: 1;
}

.checkblock2 {
  flex-grow: 4;
}

.checkbox,
.radio {
  padding-left: 25px;
  line-height: 1.5 !important;
  display: inherit !important;
}

.modal-card-foot,
.modal-card-head {
  padding: 15px 20px !important;
}

.modal-card-title {
  color: #363636 !important;
  font-size: 1.5rem !important;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif !important;
}

.delete,
.modal-close {
  background-color: rgb(187, 200, 213) !important;
}

.delete:focus,
.delete:hover,
.modal-close:focus,
.modal-close:hover {
  background-color: rgb(170, 189, 208) !important;
}

.addLocation::before {
  font-size: 24px !important
}

.addLocation::after {
  content: "\F034E";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 15px !important;
  position: absolute;
  left: 15px;
  bottom: 4px;
  background: #fff;
  border-radius: 50%;
}

.lavel-0 .field-label {
  display: none !important
}

table.inner-table thead th {
  color: #000000 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

table.inner-table tbody td {
  color: rgba(0, 0, 0, .6) !important;
}

table.inner-table tbody b {
  color: rgba(0, 0, 0, .8) !important;
  cursor: pointer;
}

table.inner-table tbody b:hover {
  text-decoration: underline;
}

.sub-title {
  font-family: 'open sans', sans-serif !important;
  font-weight: normal !important;
  font-size: .9rem !important;
}

.inner-title {
  font-family: 'open sans', sans-serif !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
}

/* Editor CSS */

.rdw-option-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-option-active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}

.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #F1F1F1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.rdw-dropdownoption-highlighted {
  background: #F1F1F1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}

.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left;
}

.rdw-center-aligned-block {
  text-align: center;
}

.rdw-justify-aligned-block {
  text-align: justify;
}

.rdw-right-aligned-block>div {
  display: inline-block;
}

.rdw-left-aligned-block>div {
  display: inline-block;
}

.rdw-center-aligned-block>div {
  display: inline-block;
}

.rdw-justify-aligned-block>div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px;
}

.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #F1F1F1;
}

.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #BFBDBD inset;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #BFBDBD;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #BFBDBD;
}

.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #BFBDBD;
}

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option>span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-embedded-modal-link-section {
  display: flex;
  flex-direction: column;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  margin: 10px 0;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}

.rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  display: flex;
  justify-content: center;
}

.rdw-embedded-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  display: none !important;
  /* align-items: center;
  margin-bottom: 6px;
  position: relative; */
}

.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.rdw-spinner>div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }

  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-image-modal-header {
  font-size: 15px;
  margin: 10px 0;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  height: 65px;
  width: 100%;
  color: gray;
  cursor: pointer;
  display: flex;
  border: none;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  outline: 2px dashed gray;
  outline-offset: -10px;
  margin: 10px 0;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.rdw-image-modal-url-section {
  display: flex;
  align-items: center;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  margin: 15px 0 12px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  margin: 0 5px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.rdw-history-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  position: absolute;
  left: 40%;
  top: 0;
  cursor: pointer;
  background-color: white;
}

.rdw-mention-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #F1F1F1;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white;
  z-index: 100;
}

.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.rdw-suggestion-option-active {
  background-color: #F1F1F1;
}

.rdw-hashtag-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-image-alignment-options-popup {
  position: absolute;
  ;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: 105px;
  cursor: pointer;
  z-index: 100;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment {
  position: relative;
}

.rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  display: flex;
  justify-content: center;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  display: flex;
  justify-content: flex-end;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  /* overflow: auto; */
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #dbdbdb;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  border-bottom: none !important;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  user-select: none;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.rm-main-left-margin {
  margin-left: 0 !important;
  min-height: auto !important;
}

.rm-main-left-margin .field-label {
  flex-grow: 3 !important;
}

.smallerIco i::before {
  font-size: 16px !important;
}

.progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.progress-indicator.stacked {
  display: block
}

.progress-indicator>li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1
}

.progress-indicator {
  margin: 0 0 1em;
  padding: 0;
  font-size: 80%;
  text-transform: uppercase
}

.progress-indicator .active {
  background-color: transparent;
}

.progress-indicator>li {
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #bbb;
  display: block
}

.progress-indicator>li:hover {
  color: #6f6f6f
}

.progress-indicator>li.completed,
.progress-indicator>li.completed .bubble {
  color: #65d074
}

.progress-indicator>li.skipped,
.progress-indicator>li.skipped .bubble {
  color: #3082c5
}


.progress-indicator>li .bubble {
  /* border-radius: 10px; */
  width: 40px;
  height: 40px;
  background-color: #bbb;
  display: block;
  margin: 0 auto .5em;
}

.progress-indicator>li i {
  position: absolute;
  left: 50%;
  margin-left: -9px;
  color: white;
  z-index: 99;
  font-size: 18px;
  top: 6px;
}

.progress-indicator>li .bubble:after,
.progress-indicator>li .bubble:before {
  display: block;
  position: absolute;
  top: 20px;
  width: 100%;
  height: 3px;
  content: '';
  background-color: #bbb
}

.progress-indicator>li.completed .bubble,
.progress-indicator>li.completed .bubble:after,
.progress-indicator>li.completed .bubble:before {
  background-color: #65d074;
  border-color: #65d074
}

.progress-indicator>li.skipped .bubble,
.progress-indicator>li.skipped .bubble:after,
.progress-indicator>li.skipped .bubble:before {
  background-color: #3082c5;
  border-color: #3082c5
}

.progress-indicator>li .bubble:before {
  left: 0
}

.progress-indicator>li .bubble:after {
  right: 0
}

.progress-indicator>li:first-child .bubble:after,
.progress-indicator>li:first-child .bubble:before {
  width: 50%;
  margin-left: 50%
}

.progress-indicator>li:last-child .bubble:after,
.progress-indicator>li:last-child .bubble:before {
  width: 50%;
  margin-right: 50%
}

.progress-indicator>li.active .bubble,
.progress-indicator>li.active .bubble:after,
.progress-indicator>li.active .bubble:before {
  background-color: #edb10a;
  border-color: #edb10a;
}

.progress-indicator>li.active,
.progress-indicator>li.active .bubble {
  color: #edb10a;
}

.progress-indicator>li.danger .bubble,
.progress-indicator>li.danger .bubble:after,
.progress-indicator>li.danger .bubble:before {
  background-color: #d3140f;
  border-color: #d3140f
}

.progress-indicator>li.danger .bubble {
  color: #d3140f;
}

.progress-indicator>li.danger,
.progress-indicator>li.danger .bubble {
  color: #d3140f;
}

.box-container {
  padding: 0px 5px;
  border: 1px solid #dbdbdb;
  min-width: 150px;
  display: inline-block;
  text-align: center;
  min-height: 22px;
}

.datasheet-content>p {
  margin-bottom: 0;
  line-height: 2rem;
}

.icon-red {
  color: red;
}

.is-xs i::before {
  font-size: small;
}

.ds-ul-text {
  display: inline-block;
  width: 150px;
  text-align: center;
  border-bottom: 1px solid #9b9b9b;
}

.download-menu {
  font-size: 1rem;
  position: absolute;
  right: 0;
  margin-top: -39px;
  z-index: 9;
}

.addSpecificaton::before {
  color: #00829c !important;
  font-size: 24px !important
}

.addSpecificaton::after {
  content: "\F034E";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 15px !important;
  position: absolute;
  left: 15px;
  bottom: 4px;
  background: #fff;
  border-radius: 50%;
  color: #00829c;
}

.table.is-hoverable.is-striped tbody tr.noBg {
  background-color: #c7e5ea !important;
}

.table.is-hoverable.is-striped tbody tr.noBg:not(.is-selected):hover,
.table.is-hoverable.is-striped tbody tr.noBg:hover {
  background-color: #c7e5ea !important;
}

.hd-bg {
  border: 1px solid #c5d4d7;
  border-radius: 2px;
}

.is-size-normal {
  font-size: 0.97em !important;
}

.is-size-6a {
  font-size: 0.9em !important;
}

.row-btngroup-top {
  position: absolute;
  top: -26.3px;
  right: -1px;
  border: 1px solid #c5d4d7;
  border-bottom: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.row-btngroup-bottom {
  position: absolute;
  bottom: -26px;
  left: 0px;
  background: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.row-btngroup-top .mdi::before,
.row-btngroup-bottom .mdi::before {
  font-size: 1rem !important;
}

.row-btngroup-top .button,
.row-btngroup-bottom .button {
  background-color: transparent !important;
  border: none !important;
  transition: all .4s;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 1.4em;
  padding-right: 1.4em;
  padding-top: calc(.5em - 1px);
}

.row-btngroup-top .button:hover,
.row-btngroup-bottom .button:hover {
  color: rgba(0, 0, 0, 0.9);
  transition: all .4s;
}

.btngroup-active::after {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  content: "";
  height: 1px;
  left: 10%;
  position: absolute;
  width: 80%;
}

.c-of-del::after {
  content: 'Old Value: ' !important;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 100px !important;
  height: 15px;
  z-index: 5;
  text-indent: 0;
  color: #ff6f6f;
}

.c-of-add::after {
  content: 'New Value: ' !important;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 100px !important;
  height: 15px;
  z-index: 5;
  color: #3eb93e;
  text-indent: 0;
}

.c-json-p {
  padding-left: 80px !important;
}

.recharts-layer {
  font-size: 10px !important;
}

.is-tiny i::before {
  font-size: 1rem !important;
}

.line-height-normal i::before {
  line-height: 1rem !important;
}

.button.is-change {
  background-color: #eeb805;
  border-color: transparent;
  color: #fff;
}

.button.is-grey {
  background-color: #9a9898;
  border-color: transparent;
  color: #fff;
}

.recharts-legend-item-text {
  color: #000000 !important;
}

.recharts-pie-label-text {
  fill: #000000 !important;
}

del {
  color: red;
}

ins {
  color: orange;
}

/* label css */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;600&display=swap");

.label-box {
  padding: .5mm 2mm;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  font-size: 3mm;
  color: #000000;
  line-height: normal;
  border: 1px solid transparent;
}

.label-bordered {
  border: 1px solid #333333;
  border-radius: 10px;
}

table.label-container {
  border-collapse: collapse;
  margin: 1mm auto;
  width: 100%;

}

table.label-container tr {
  align-items: flex-start;
  background: #fff;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

table.label-container tr td {
  display: table-cell;
  padding: 1mm;
  width: 100%;
}

table.label-container b {
  font-weight: 600;
}

.label-row {
  display: flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label-column {
  flex-grow: 1;
}

.label-flex-grow-0 {
  flex-grow: 0;
}

.is-flex {
  display: flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
}

.is-wrap {
  flex-wrap: wrap;
}

.is-nowrap {
  flex-wrap: nowrap;
}

.db-report-grid .button {
  /* margin: -38px .2rem 0 .2rem !important; */
  height: 2.1rem !important;
  padding-left: .6rem !important;
  padding-right: .6rem !important;
}

.db-report-grid .loadmore .button {
  margin-top: 0.75em !important;
  height: 2.5rem !important;

}

.print,
.printfooter {
  display: none !important;
}

.border-1 {
  border: 1px solid #ddd;
}

.template-field {
  background: #e5e5e5;
  padding: 5px;
  border: 1px solid #333333;
}

.card-header {
  box-shadow: 0 .125em .25em #0a0a0a4f !important;
}

.card-footer-item {
  border: none !important;
  flex-basis: 1 !important;
}

.react-toggle-track {
  background-color: #9db7bb !important;
  width: 55px !important;
}

.react-toggle-thumb {
  border: none !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: #00d1b2 !important;
}

.react-toggle-track-check,
.react-toggle-track-x {
  font-size: smaller;
  color: #ffffff;
  font-weight: 700;
  padding-top: 5px;
  font-family: inherit;
}

.react-toggle-track-x {
  right: 15px !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 32px !important;
}

div.column {
  max-width: 100% !important;
}

.help-btn {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
}

.title,
.subtitle {
  display: flex;
  align-items: center;
}

.helpText p {
  font-size: .9rem;
  margin-bottom: 1rem;
  line-height: 21px;
}

.section-preview {
  overflow: auto;
  height: 200px;
}

.addShadow {
  display: block;
  box-shadow: lightgray 0px 0px 3px 3px;
}

.section-preview svg rect[stroke="blue"],
.chemical-svg svg rect[stroke="blue"] {
  opacity: 0;
}

.section-preview g rect:first-of-type {
  width: 100% !important;
}

.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) rect[fill="rgb(192,192,192)"],
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(4) rect[fill="rgb(192,192,192)"],
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(5) rect[fill="rgb(192,192,192)"] {
  fill-opacity: 0 !important;
}

.jsa-resetDiv>.jsa-resetDiv:nth-of-type(4),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(5) {
  background-color: #d4d9e0;
}

.jsa-resetDiv>.jsa-resetDiv:nth-of-type(5) text,
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(5) polygon,
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) ellipse:nth-of-type(1),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) ellipse:nth-of-type(2),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) g>g:first-of-type,
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) rect:nth-of-type(2),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) rect:nth-of-type(8),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) rect:nth-of-type(7),
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) rect[fill="blue"],
.jsa-resetDiv>.jsa-resetDiv:nth-of-type(3) text:last-of-type {
  opacity: 0;
  display: none;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.table thead th label {
  color: rgba(255, 255, 255, 0.9) !important;
}

.test {
  fill: white;
}

/* ketcher style */

.ketcher-editor {
  border-radius: 4px;
  border: 1px solid hsl(0deg, 0%, 86%);
}

.css-cssveg,
.css-whv5bt,
.css-psxza5,
.App-module_top__SBeSV button:nth-child(2),
.App-module_top__SBeSV button:nth-child(3),
.App-module_top__SBeSV button:nth-child(4),
.App-module_top__SBeSV button:nth-child(5),
.App-module_top__SBeSV button:nth-child(6),
.App-module_top__SBeSV button:nth-child(10),
.App-module_top__SBeSV button:nth-child(11),
.App-module_top__SBeSV button:nth-child(12),
.App-module_top__SBeSV button:nth-child(13),
.App-module_top__SBeSV button:nth-child(14),
.App-module_top__SBeSV button:nth-child(15),
.App-module_top__SBeSV button:nth-child(16),
.App-module_top__SBeSV button:nth-child(17),
.App-module_top__SBeSV button:nth-child(18),
.App-module_top__SBeSV button:nth-child(19) {
  display: none !important;
}

.BottomToolbar-module_root__kjkSm .BottomToolbar-module_group__b-pGt:not(:last-of-type) {
  border-right: none !important;
}

.BottomToolbar-module_root__kjkSm .BottomToolbar-module_group__b-pGt:nth-child(2) {
  display: none !important;
}

.editor-html input,
.editor-html label {
  user-select: none !important;
  pointer-events: none;
}

.myExperiment .editor-wrapper {
  overflow-y: auto !important;
  max-height: 400px !important;
}

.ds-var {
  padding: 4px 6px 1px;
  margin: 0px 3px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(232, 232, 232);
  border: 1px solid rgb(221, 221, 221);
  font-size: 0.9rem;
  line-height: 1rem;
  box-shadow: none;
  min-width: 100px;
  text-align: center;
  opacity: 0.9;
}

.jodit-status-bar {
  display: none !important;
}

.add-var {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ececec;
  padding: 1px 11px 1px 7px;
  margin: 4px 4px 4px 4px;
  min-width: 100px;
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  transition: padding 0.3s;

}

.jodit-delete {
  display: none;
  width: 20px;
  position: absolute;
  top: -8px;
  right: -6px;
  cursor: pointer;
}

.jodit-delete svg {
  width: 18px;
  fill: #aaa4a4;
}

.add-var:hover .jodit-delete {
  display: flex;
}

.jodit ol {
  list-style: decimal;
  padding: revert;
  margin: revert;
}

.jodit ul {
  list-style: disc;
  padding: revert;
  margin: revert;
}

table.MsoNormalTable {
  position: sticky;
  left: 1px;
}

.datasheet-content table td {
  border: 1px solid #ddd !important;
  padding: 2px !important;
}

.datasheet-content .add-var:hover .jodit-delete {
  display: none !important;
}

.box-title {
  color: #737373 !important;
  font-size: 2.4em !important;
  justify-content: center;
  min-height: 100px;
}

.datasheet-select {
  display: inline-flex;
}

.datasheet-select>div {
  width: 200px;
}


.jodit-toolbar-editor-collection_size_small {
  background: #e5e9ee !important;
  color: white;
}

.jodit-toolbar-button_speechToText.active-mic {
  background-color: rgb(26, 170, 222);
}

.activeBackupBtn {
  background-color: #084c68;
  color: #fff;
  transition: background-color 0.3s ease;
}

.EditorIntervel-BackupBtn {
  cursor: pointer;
}

.jodit-toolbar-button_Sections,
.jodit-toolbar-button_Chemical_Structure,
.jodit-toolbar-button_Protocol .jodit-toolbar-button__button,
.jodit-toolbar-button_Chemical_Structure .jodit-toolbar-button__button,
.jodit-toolbar-button_Template .jodit-toolbar-button__button,
.jodit-toolbar-button_Spreadsheet .jodit-toolbar-button__button,
.jodit-toolbar-button_Execute_Test .jodit-toolbar-button__button {
  background-color: #084c68;
  color: #fff;
  padding: 10px;
}

.jodit-toolbar-button_Protocol .jodit-toolbar-button__button:hover,
.jodit-toolbar-button_Chemical_Structure .jodit-toolbar-button__button:hover,
.jodit-toolbar-button_Template .jodit-toolbar-button__button:hover,
.jodit-toolbar-button_Execute_Test.jodit-toolbar-button__button:hover {
  color: #084c68;
  transition: 0.3s ease;
}

@media screen and (max-width: 743px) {
  .sidebar {
    transition: all 0.5s ease;
    width: 0px;
  }
}

@media (min-width: 340px) and (max-width: 2579px) {
  .sidebar {
    width: 0rem;
    transition: left 0.5s ease;
  }

  main {
    margin-left: 0rem;
    transition: margin-left 0.5s ease;

  }

  body {
    height: auto !important;
  }
}

.sidebarbutton {
  position: fixed;
  top: 14rem;
  left: 0px;
  z-index: 20;
  transition: left 0.5s ease;
}

.sidebarbuttonwide {
  left: 24rem !important;
  transition: left 0.5s ease;

}

.sidebar-wide {
  width: 24rem;
  transition: left 0.5s ease;

}

@media (min-width: 900px) {
  .sidebarbutton {
    display: block;
  }
}

@media (max-width: 2579px) {
  body {
    background: #fff !important;
  }
}

@media (min-width: 2579px) {
  .sidebarbutton {
    display: none !important;
  }
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 12px;
  cursor: pointer;
  margin: auto;
  display: block;
  height: calc(4px * 3 + 11px * 2);
  /* background: none;  */
  border: none;
  background: #6779916e;
  height: 28rem;
  border-bottom-right-radius: 500px;
  border-top-right-radius: 500px;
  transition: left 0.5s ease;
}

.bar {
  position: absolute;
  left: -37px;
  right: 0;
  height: 3px;
  border-radius: 2px;
  background: #fff;
  transition: all 0.5s cubic-bezier(.5, -0.35, .35, 1.5);
  width: 85px;
  transform: rotate(90deg);


}

.bar--top {
  bottom: calc(50% + 8px);
  /* transform: rotate(90deg); */
}

.bar--middle {
  top: calc(50% - 3.5px);

}

.bar--bottom {
  top: calc(50% + 6px);
}

.sidebarbuttonwide .bar--top {
  /* transform: rotate(-135deg);
  bottom: calc(50% - 2px); */
  background: #084c68;
}

.sidebarbuttonwide .bar--middle {
  opacity: 0;
  /* transform: rotate(-135deg); */
  background: #13b8db;
}

.sidebarbuttonwide .bar--bottom {
  top: calc(50% - 2px);
  transform: rotate(-225deg);
  background: #13b8db;
}

/* table tr td{
  max-width: 5px;
} */


.recharts-pie-sector {
  filter: drop-shadow(-23px 16px 10px rgba(0, 0, 0, 0.4));
}

.highcharts-pie-series path {
  filter: drop-shadow(-23px 16px 10px rgba(0, 0, 0, 0.4));
}

.recharts-pie-labels {
  text-shadow: -11px 10px 6px rgba(0, 0, 0, 0.4);
}

.recharts-line path {

  filter: drop-shadow(-10px 10px 3px rgba(0, 0, 0, 0.4));
}

.xAxis,
.yAxis {
  /* filter: drop-shadow(0px 17px 5px rgba(0, 0, 0, 0.7)); */
  filter: drop-shadow(-11px 13px 4px rgba(0, 0, 0, 0.7));

}

.highcharts-3d-front {
  filter: drop-shadow(-23px 16px 10px rgba(0, 0, 0, 0.4));
}

.highcharts-axis-labels text {
  filter: drop-shadow(-13px 13px 4px rgba(0, 0, 0, 0.4));
}

.highcharts-area-series {
  filter: drop-shadow(-9px -6px 3px rgba(0, 0, 0, 0.4));
}


.recharts-line-dots {
  filter: drop-shadow(-5px 15px 3px rgba(0, 0, 0, 0.7));
}

.highcharts-credits {
  display: none !important;
}

.highcharts-yaxis text {
  margin-bottom: 20px;
  text-shadow: 12px -7px 6px rgba(0, 0, 0, 0.4);
}

/* responsive */
.field.is-horizontal1 {
  display: block !important;
}

.hoverEffectBtn {
  margin-bottom: 10px;
  background: #084c68 !important;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
  color: #fff;
  font-size: 12px;
  border-radius: 10px !important;
  border: unset;
  z-index: 1;
  position: relative;
  box-shadow: -3px -4px 18px 4px rgba(246, 244, 244, 0.3);
  transition: all 300ms;
  overflow: hidden;
}

.hoverEffectBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #fff !important;
  border: 1px solid #dce1e6;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(255, 242, 242, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 500ms;
}

.hoverEffectBtn:hover {
  color: #000;
  cursor: pointer;
}

.hoverEffectBtn:hover::before {
  width: 100%;
}

.recharts-default-tooltip {
  background-color: transparent !important;
  border: none !important;
  white-space: nowrap;
}

.recharts-tooltip-wrapper:focus-visible,
.select-bar:focus-visible {
  outline: none !important;
}

.select-bar {
  margin-left: 10px;
  background-color: transparent !important;
  border: none !important;
}

.highcharts-no-tooltip {
  display: block !important;
}

.update-td td {
  width: 50%;
}

.theme-div {
  display: flex !important;
  align-items: center !important;
}

.DimentionalViewBtn {
  display: flex;
  justify-content: flex-end;
}

.btn-container {
  /* display: table-cell; */
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch>.btn-color-mode-switch-inner {
  margin: 0px;
  width: 50px;
  height: 25px;
  background-color: #e9ecef !important;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
}

.btn-color-mode-switch>.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  top: 6px;
  right: 8px;
  color: #222;
}

.btn-color-mode-switch>.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 24px;
  height: 23px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  font-size: 10px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 1px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #222;
  font-weight: 600;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+.btn-color-mode-switch-inner {
  background-color: #e9ecef !important;
}

.btn-color-mode-switch input[type="checkbox"]:checked+.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 25px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 10px;
}

/* ----------- */
.themeOptiondropdown {
  position: relative;
  display: inline-block;
}

.themeOptioncustom-select-btn {
  cursor: pointer;
  padding: 6px 30px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  user-select: none;
  position: relative;
  z-index: 1;
}

/* .themeOptioncustom-select-btn i {
  margin-left: 5px;
} */
.themeOptiondropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 8px 0;
  margin: 0;
  list-style-type: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.themeOptiondropdown-menu.active {
  display: block;
}

.themeOptionthemeOption {
  padding: 8px 16px;
  cursor: pointer;
}

.themeOptionthemeOption:hover {
  background-color: #f0f0f0;
}

.themeOptionsub-themeOption {
  padding: 8px 16px;
  cursor: pointer;
}

.themeOptionsub-themeOption:hover {
  background-color: #f0f0f0;
}

.themeOptiontheme-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 10px;
}

/* --------------------------------- */
.navbar.lims-eln-nav {
  /* background-color: #05445E !important; */
  background-color: #084c68 !important;
}

#root.lims-eln .react-tabs__tab--selected,
.button.is-link {
  background: #05445E !important;
  color: #ffff;
}

.sidebar.lims-eln .nav-child-gp .nav-link {
  background-color: #084c68 !important;
}

.sidebar.lims-eln .nav-child-gp .nav-link:hover {
  background-color: #1c7ba4cf !important;
  transform: scale(1.02);
}

.sidebar.lims-eln .nav-child-gp .nav-link {
  border-top: 2px solid #052f4047 !important;
  border-bottom: 1px solid #052f408a !important;
  border-left: 2px solid #052f40d1 !important;
  border-right: 1px solid #052f40d1 !important;
}

.sidebar.lims-eln .nav-child-gp {
  background-color: #084c68 !important;

}

#root.lims-eln .button.is-primary:not(.is-outlined) {
  background-color: #084c68 !important;

}

#root.lims-eln .table:not(.inner-table) thead {
  background: #084c68 !important;
}

.responsive-tr td {
  max-width: 300px !important;
}

.jodit-toolbar__box {
  position: relative;
}

.userProfileicon {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.userName {
  padding-top: 10px;
}

#passNote {
  margin-top: 10px !important;
}

#passNote small {
  font-size: 11px !important;
  text-align: justify !important;
  margin-top: 10px !important;
}


@keyframes active-mic {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.35, 1.35, 1.35);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.active-mic span {
  animation-name: active-mic;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.max-width {
  max-width: 58vw !important;
}


.x-spreadsheet-toolbar {
  width: 61vw !important;
  min-width: 61vw !important;
  max-width: 100vw !important;
}

.x-spreadsheet-sheet {
  height: 230px !important;
  display: flex !important;
  overflow: scroll;
  /* width:61vw !important; */
}

.x-spreadsheet {
  z-index: 999;
}


.xlTable {
  width: 1000px;
  width: 1000px;
}

#xlTable {
  width: 1000px !important;
  width: 1000px !important;
}

.modalcontainer {
  position: 'fixed';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  z-index: '9999';
  background-color: 'white';
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.2)';
  border-radius: '8px';
  overflow: 'hidden';
  max-width: '90vw';
  max-height: '80vh';
  display: 'flex';
 flex-direction: 'column';
 align-items: 'center';
}
.radio_label .ReactTags__selected{
  max-height: 120px;
  overflow: scroll;
  text-align: justify;
}
.radio_label .ReactTags__tags{
  padding: 0px 3px;
  
}
.radio_label .ReactTags__tagInput{
  width: 500px;
}
.radio_label  span.ReactTags__tag{
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio_label span{
  height: 60px;
  word-break: break-all;
}  
.radio_label 
.rootColor{
  background-color: #084c68 !important;
  color:#fff !important;
}

.MsoTableGrid{
  margin-left: 0px !important;
}
.MsoNormal{
  margin-left: 0px !important;
}

/* .field-body{
  display: block !important;
} */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: #084c68;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.modal-card-heads{
  padding: 15px 20px !important;
  background-color: #084c68;
}
.modal-card-titles{
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;

}
.radiomark1{
  top:0;
  left:0;
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: #084c68;
  border-radius: 50%;
  color:white;
}

.custom-radio1{
  position: absolute;
  left: 5px;
  top: 5px;
  width: 7px;
  height: 7px;
  background-color: #f1f6f8;
  border-radius: 50%;
  color:white;
}