body {
    background: $lims-gradient;
    background-color: $lims-bgdark;
}

/* Side Navigation */
.sidebar {
    position: fixed;
    top: 4rem;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow-y: auto;
    transform: translate3d(0, 0, 0);
    transition: width 0.3s, left 0.3s;

    &.lims {
        background-color: tint($lims-bgdark, 92%);

        .nav-child-gp {
            background-color: $lims-bgdarker;
            background: $lims-navgradient;

            &:hover {
                background: tint($lims-bgdarker, 1%);
            }

            .nav-link {
                border-top: 2px solid tint($lims-bgdark, 3%);
                border-bottom: 1px solid shade($lims-bgdarker, 4%);

                &:hover {
                    border-top: 2px solid tint($lims-bgdarker, 1%);
                }

                .mtrl-manage::after,
                .smpl-manage::after,
                .clm-manage::after,
                .ins-manage::after,
                .sol-manage::after,
                .lb-manage::after,
                .chm-manage::after,
                .stb-manage::after,
                .md-manage::after,
                .cul-manage::after,
                .vs-manage::after {
                    background: $lims-bgdarker !important;
                }

            }
        }

        li {
            &.sidebar-menu-item-active {
                background: tint($lims-bgdark, 80%) !important;

                .mdi,
                .sidebar-label {
                    color: $lims-bgdark !important;
                }

                i::after {
                    background: tint($lims-bgdark, 80%) !important;
                }
            }

            &.sidebar-menu-item {
                &:hover {
                    background: tint($lims-bgdark, 80%) !important;

                    i::after {
                        background: tint($lims-bgdark, 80%) !important;
                    }
                }
            }
        }
    }

    &.lims-eln {
        background-color: tint($lims-eln-bgdark, 92%);
        .nav-child-gp {
            background-color: $lims-eln-bgdarker;
            background: $lims-eln-navgradient;

            &:hover {
                background: shade($lims-eln-bgdarker, 8%);
            }

            .nav-link {
                border-top: 2px solid tint($lims-eln-bgdark, 2%);
                border-bottom: 1px solid shade($lims-eln-bgdarker, 6%);

                &:hover {
                    border-top: 2px solid tint($lims-eln-bgdarker, 1%);
                }
            }

            .mtrl-manage::after,
            .smpl-manage::after,
            .clm-manage::after,
            .ins-manage::after,
            .sol-manage::after,
            .lb-manage::after,
            .chm-manage::after,
            .stb-manage::after,
            .md-manage::after,
            .cul-manage::after,
            .vs-manage::after {
                background: $lims-eln-bgdarker !important;
            }
        }
        li {
            &.sidebar-menu-item-active {
                background: tint($lims-eln-bgdark, 80%) !important;

                .mdi,
                .sidebar-label {
                    color: $lims-eln-bgdark !important;
                }

                i::after {
                    background: tint($lims-eln-bgdark, 80%) !important;
                }
            }

            &.sidebar-menu-item {
                &:hover {
                    background: tint($lims-eln-bgdark, 80%) !important;

                    i::after {
                        background: tint($lims-eln-bgdark, 80%) !important;
                    }
                }
            }
        }
    }

    &.eln {
        background-color: tint($eln-bgdark, 92%);
        .nav-child-gp {
            background-color: $eln-bgdarker;
            background: $eln-navgradient;

            &:hover {
                background: shade($eln-bgdarker, 9%);
            }

            .nav-link {
                border-top: 2px solid tint($eln-bgdark, 2%);
                border-bottom: 1px solid shade($eln-bgdarker, 8%);

                &:hover {
                    border-top: 2px solid tint($eln-bgdarker, 1%);
                }

                .mtrl-manage::after,
                .smpl-manage::after,
                .clm-manage::after,
                .ins-manage::after,
                .sol-manage::after,
                .lb-manage::after,
                .chm-manage::after,
                .stb-manage::after,
                .md-manage::after,
                .cul-manage::after,
                .vs-manage::after {
                    background: $eln-bgdarker !important;
                }
            }
        }

        li {
            &.sidebar-menu-item-active {
                background: tint($eln-bgdark, 80%) !important;

                .mdi,
                .sidebar-label {
                    color: $eln-bgdarker !important;
                }

                i::after {
                    background: tint($eln-bgdark, 80%) !important;
                }
            }

            &.sidebar-menu-item {
                &:hover {
                    background: tint($eln-bgdark, 80%) !important;

                    i::after {
                        background: tint($eln-bgdark, 80%) !important;
                    }
                }
            }
        }
    }

    .mdi::before {
        font-size: 18px !important;
    }

    ul {
        margin: 0;
        padding-left: 0;
        list-style: none;

        li {
            display: block;
            width: 100%;

            &:nth-child(1) {
                i::after {
                    background: transparent !important;
                }
            }

            &.nav-child-gp {
                padding-left: 3px;

                &:nth-child(1) {
                    box-shadow: inset 3px 0 0 #ed7d31 !important;
                }

                &:hover {
                    box-shadow: inset 3px 0 0 #13b8db;
                }

                &.nav-p {
                    box-shadow: inset 3px 0 0 #13b8db;
                }

                .nav-link {
                    border-top-style: ridge;
                }

                span,
                i::after,
                i::before {
                    color: shade(#ffffff, 20%) !important
                }
            }

            &.nav-child-1 {
                box-shadow: inset 3px 0 0 #13b8db;

                i &::after {
                    background: transparent !important;
                }
            }

            &.nav-child-2 {
                background: #f7f9fa;
                box-shadow: inset 3px 0 0 rgb(0, 209, 178);

                .icon {
                    visibility: hidden !important;
                }
            }

            &.sidebar-menu-item {
                -webkit-transition: background-color 0.1s ease-out;
                -moz-transition: background-color 0.1s ease-out;
                -o-transition: background-color 0.1s ease-out;
                transition: background-color 0.1s ease-out;

                &:hover {

                    i::after {
                        -webkit-transition: background-color 0.1s ease-out;
                        -moz-transition: background-color 0.1s ease-out;
                        -o-transition: background-color 0.1s ease-out;
                        transition: background-color 0.1s ease-out;
                    }
                }
            }

            &.sidebar-menu-item-active {
                i::after {
                    -webkit-transition: background-color 0.1s ease-out;
                    -moz-transition: background-color 0.1s ease-out;
                    -o-transition: background-color 0.1s ease-out;
                    transition: background-color 0.1s ease-out;
                }
            }

            div {
                display: flex;
                padding: 9px 16px;
                position: relative;
                transition: all .4s;
                cursor: pointer;
                overflow: hidden;
                -webkit-user-select: none;
                user-select: none;
                color: rgba(0, 0, 0, 0.7);
                font-family: inherit;
                justify-content: space-between;

                span.sidebar-label {
                    color: rgba(0, 0, 0, 0.6);
                    font-weight: 400;
                    flex-grow: 1 !important;
                    display: flex;
                    align-items: center;
                    line-height: 1.2rem;
                    -webkit-transition: color 0.1s ease-out;
                    -moz-transition: color 0.1s ease-out;
                    -o-transition: color 0.1s ease-out;
                    transition: color 0.1s ease-out;
                }

                .icon {
                    margin-right: 1rem;
                    vertical-align: middle;

                    .mdi {
                        color: #666666 !important;
                        -webkit-transition: color 0.1s ease-out;
                        -moz-transition: color 0.1s ease-out;
                        -o-transition: color 0.1s ease-out;
                        transition: color 0.1s ease-out;
                    }

                }
            }
        }
    }
}